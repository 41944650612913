<template lang="pug">
v-card(flat color="transparent")
  v-row
    v-col(cols="12")
      v-data-table(
        ref="table"
        :headers="headers"
        :items="tableItems"
        :show-expand="false"
        :expanded.sync="expanded"
        item-key="name"
        :loading="isLoadingAssets"
        :loading-text="$t('assets.loading')"
        @click:row="(e, o) => $router.push({ name: 'asset', params: { id: o.item.id } })"
        :page.sync="page"
        @page-count="pageCount = $event"
        :sort-by.sync="sortByInner"
        :sort-desc.sync="sortDescInner"
        dense
        :hide-default-footer="!showItemsPerPage"
        :hide-default-header="hideDefaultHeader"
        :items-per-page.sync="itemsPerPage"
      )
        template(v-slot:item.name="{ item }")
          OrganizationAvatar.mr-2(
            :organization-id="item.organizationId"
            size="30"
          )
          v-avatar.mr-2(v-if="getAssetType(item.assetTypeId)" size="30")
            v-icon(small) {{ getAssetType(item.assetTypeId).icon }}
          strong {{ item.name }}
        //- template(v-slot:item.id="{ item }")
        //- template(v-slot:item.line="{ item }")
        //-   v-responsive(
        //-     :min-width="100"
        //-   )
        //-     v-sparkline(
        //-       v-if="showGraph"
        //-       :values="item.graphValues"
        //-       :gradient="['green', 'orange', 'red']"
        //-       :smooth="true"
        //-       :padding="3"
        //-       :line-width="6"
        //-       stroke-linecap="round"
        //-       :fill="false"
        //-       type="trend"
        //-     )
        template(v-slot:item.lastContact="{ item }")
          v-tooltip(
            v-if="!item.asset.isOnline && item.asset.lastContact"
            top
          )
            template(v-slot:activator="{ attrs, on }")
              v-icon.mr-1(
                v-bind="attrs"
                v-on="on"
                small
                color="error"
              ) mdi-wifi-off
            span {{ $t('offline-at-least-hour') }}
          template(v-if="item.lastContact")
            v-icon.mr-1(
              small
            ) mdi-update
            span.caption {{ item.lastContact | toMoment | momentFromNow }}
          template(v-else) -
        template(v-slot:item.details="{ item }")
          template(v-if="item.assetTypeId === ASSET_TYPE_GENERATOR")
            v-tooltip(
              v-if="getSensorValue(item.asset, 'FUEL')"
              top
            )
              template(v-slot:activator="{ attrs, on }")
                v-chip(
                  color="transparent"
                  v-bind="attrs"
                  v-on="on"
                )
                  v-icon(
                    left
                    small
                    :color="getFuelColor(getFuelLevel(item.asset))"
                  ) mdi-fuel
                  span
                  | {{ getFuelLevel(item.asset).toFixed(0) }}
                  span(class="grey--text") %
              | {{ $t('fuel-level') }}
              | {{ getFuelLevel(item.asset).toFixed(0) }}%
            v-avatar.white.mr-1.primary--text(
              v-if="getSensorValue(item.asset, 'FUEL')"
              size="20"
            )
              strong G
            v-avatar.white.mr-1(
              v-if="getSensorValue(item.asset, 'VTRON_SOC')"
              size="20"
            )
              v-icon(small color="primary") mdi-car-battery
            template(v-for="(sensor, t) in genSensorTypes")
              template(v-if="getSensorValue(item.asset, sensor.key) !== undefined")
                span.grey--text.text--darken-1.mr-1(
                  :key="`avatar-${t}`"
                ) {{ sensor.quantity }}
                span.mr-4(
                  :key="`span-${t}`"
                )
                  | {{ sensor.parse(item.asset) | round }}
                  span.grey--text(v-html="sensor.unit \
                    ? sensor.unit : getUnit(item.asset, sensor.key)")
            template(
              v-if="getSensorValue(item.asset, 'PS_ENABLED')"
            )
              v-chip.mr-1.primary--text(
                x-small
                color="white"
              )
                strong NET
              v-icon.mr-1(
                small
              ) mdi-sine-wave
              span {{ getSensorValue(item.asset, 'PS_NET') }}
              span.mr-1.grey--text A
              span ({{ getSensorValue(item.asset, 'PS_NET_MAX') }}
              span.grey--text A
              span.mr-4 )
              template(v-if="getSensorValue(item.asset, 'PS_ASS_ENABLED')")
                v-icon.mr-1(
                  small
                ) mdi-play-pause
                span
                  | {{ getSensorValue(item.asset, 'PS_ASS_START') }}
                  | -
                  | {{ getSensorValue(item.asset, 'PS_ASS_STOP') }}
                span.mr-4.grey--text A
            template(v-else)
              span -
          template(v-if="item.assetTypeId === ASSET_TYPE_INVERTER")
            v-tooltip(
              v-if="getSensorValue(item.asset, 'VTRON_SOC') !== undefined"
              top
            )
              template(v-slot:activator="{ attrs, on }")
                v-chip(
                  color="white"
                  v-bind="attrs"
                  v-on="on"
                )
                  v-icon(
                    left
                    small
                    :color="getFuelColor(getBatteryLevel(item.asset))"
                  ) {{ (getBatteryIcon(getBatteryLevel(item.asset) / 100)) }}
                  span
                    | {{ Math.round(getBatteryLevel(item.asset)) }}
                  span(class="grey--text") %
              | {{ $t('battery-level') }}
              | &nbsp;{{ Math.round(getBatteryLevel(item.asset)) }}
              | %
          template(v-if="item.assetTypeId === ASSET_TYPE_COOLING_CELL")
            v-chip(
              v-if="getSensorValue(item.asset, 'ROOM_PB1')"
              color="white"
            )
              v-icon(
                left
                small
                color="primary"
              ) mdi-thermometer
              span {{ getTemp(item.asset).toFixed() }}
              span(class="grey--text") &deg;C
        template(v-slot:item.activeAlarms="{ item }")
          div(
            v-if="!item.asset.hasAlerts"
          )
            v-tooltip(
              top
            )
              template(v-slot:activator="{ attrs, on }")
                v-icon(
                  v-bind="attrs"
                  v-on="on"
                  small
                  color="success"
                ) mdi-check-circle
              span {{ $tc('assets.alarms-malfunctions', 0) }}
          v-row(v-else align="center" no-gutters)
            v-col(
              v-if="item.malfunction"
            )
              v-tooltip(
                top
              )
                template(v-slot:activator="{ attrs, on }")
                  v-icon(
                    v-bind="attrs"
                    v-on="on"
                    small
                    color="warning"
                  ) mdi-alert-circle-outline
                span {{ $t('assets.malfunction.reported') }}: {{ item.malfunction.text }}
            v-col(
              v-if="item.asset.hasActiveAlarmErrors"
            )
              div
                v-icon(small color="error") mdi-bell-alert-outline
                span.ml-2 {{ item.asset.numberOfAlarmErrors }}
            v-col(
              v-if="item.asset.hasActiveAlarmWarnings"
            )
              div
                v-icon(small color="warning") mdi-bell-outline
                span.ml-2 {{ item.asset.numberOfAlarmWarnings }}
        template(v-slot:item.actions="{ item }")
          v-tooltip(
            top
          )
            template(v-slot:activator="{ attrs, on }")
              v-btn(
                v-bind="attrs"
                v-on="on"
                small
                icon
                :to="{ name: 'asset', params: { id: item.id } }"
              )
                v-icon(small) mdi-eye-outline
            | {{ $t('item.view', { item: item.name }) }}
          slot(
            name="extra-actions"
            v-bind:item="item"
          )
    v-col(v-if="!showItemsPerPage" cols="12" align-self="end")
      v-pagination(
        v-if="pageCount > 1"
        v-model="page"
        :length="pageCount"
      )
  v-progress-linear(
    v-if="interval && pageCount > 1"
    v-model="pageProgress"
    active
    absolute
    bottom
    :height="10"
    rounded
  )
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

import {
  SENSOR_TYPES,
  ASSET_TYPE_GENERATOR,
  ASSET_TYPE_INVERTER,
  ASSET_TYPE_COOLING_CELL,
} from '@/helpers/consts';
import OrganizationAvatar from '@/components/organization/OrganizationAvatar.vue';

export default Vue.extend({
  name: 'AssetsTable',

  components: {
    OrganizationAvatar,
  },
  props: {
    assets: {
      type: Array,
      default: () => [],
    },
    actions: {
      type: Boolean,
      default: true,
    },
    details: {
      type: Boolean,
      default: true,
    },
    interval: {
      type: Number,
      default: 0,
    },
    showItemsPerPage: {
      type: Boolean,
    },
    // showGraph: {
    //   type: Boolean,
    // },
    sortBy: {
      type: Array,
      default: () => [],
    },
    sortDesc: {
      type: Array,
      default: () => [],
    },
    hideDefaultHeader: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      expanded: [],
      SENSOR_TYPES,
      page: 1,
      pageCount: 0,
      pageProgress: 0,
      update: null,
      ASSET_TYPE_GENERATOR,
      ASSET_TYPE_INVERTER,
      ASSET_TYPE_COOLING_CELL,
      sortByInner: [],
      sortDescInner: [],
      localStorageItemsPerPageKey: 'hob-monitoring:items-per-asset-page',
      itemsPerPage: 10,
    };
  },
  mounted() {
    this.startInterval();
    this.update = setInterval(() => {
      this.$forceUpdate();
    }, 1000);
    if (!this.hideDefaultHeader) {
      this.itemsPerPage = Number(localStorage.getItem(this.localStorageItemsPerPageKey) ?? 10);
    }
  },
  computed: {
    ...mapGetters(['isLoadingAssets', 'getAssetType']),
    tableItems() {
      return this.assets.map((asset) => {
        let niveau = asset.getSensorBySensorTypeKey('FUEL')?.lastMeasurement?.value;
        if (niveau === null || niveau === undefined) {
          niveau = asset.getSensorBySensorTypeKey('VTRON_SOC')?.lastMeasurement?.value;
        }
        const lastContact = asset.lastContact?.valueOf();
        const activeAlarms = asset.numberOfAlarms + (asset.malfunction ? 1 : 0);
        return {
          ...asset,
          asset,
          niveau,
          lastContact,
          activeAlarms,
        };
      });
    },
    headers() {
      const headers = [
        // {
        //   text: '',
        //   value: 'data-table-expand',
        // },
        {
          align: 'start',
          text: this.$t('name'),
          sortable: true,
          value: 'name',
        },
        // {
        //   text: 'Type',
        //   sortable: true,
        //   value: 'id',
        // },
        {
          text: this.$t('last-contact'),
          value: 'lastContact',
          sortable: true,
        },
        {
          text: this.$tc('assets.alerts', 1),
          value: 'activeAlarms',
          sortable: true,
        },
      ];

      // if (this.showGraph) {
      //   headers.push({
      //     text: 'Output',
      //     value: 'line',
      //     sortable: false,
      //   });
      // }

      if (this.details) {
        headers.push({
          text: this.$t('details'),
          value: 'details',
          sortable: false,
        });
      }

      if (this.actions) {
        headers.push({
          text: this.$t('actions'),
          value: 'actions',
          align: 'end',
          sortable: false,
        });
      }

      return headers;
    },
    genSensorTypes() {
      return [
        {
          key: 'UL1L2',
          quantity: 'U',
          parse: (item) => item.getSensorBySensorTypeKey('UL1L2').lastMeasurement.value,
        },
        {
          key: 'FGEN',
          quantity: 'F',
          parse: (item) => item.getSensorBySensorTypeKey('FGEN').lastMeasurement.value,
        },
        {
          key: 'PGEN',
          quantity: 'P',
          parse: (item) => item.getSensorBySensorTypeKey('PGEN').lastMeasurement.value,
        },
        {
          key: 'IL1',
          quantity: 'I',
          parse: (item) => item.getSensorBySensorTypeKey('IL1').lastMeasurement.value,
        },
        {
          key: 'VTRON_BATTERY_VOLTAGE',
          quantity: 'U',
          parse: (item) => item.getSensorBySensorTypeKey('VTRON_BATTERY_VOLTAGE').lastMeasurement.value,
        },
        {
          key: 'VTRON_OUT_FREQ',
          quantity: 'F',
          parse: (item) => item.getSensorBySensorTypeKey('VTRON_OUT_FREQ').lastMeasurement.value,
        },
        {
          key: 'VTRON_OUT_VOLT_1',
          quantity: 'P',
          parse: (item) => Math.max(
            item.getSensorBySensorTypeKey('VTRON_OUT_VOLT_1').lastMeasurement.value
                * item.getSensorBySensorTypeKey('VTRON_OUT_CURRENT_1').lastMeasurement.value,
            item.getSensorBySensorTypeKey('VTRON_OUT_VOLT_2').lastMeasurement.value
                * item.getSensorBySensorTypeKey('VTRON_OUT_CURRENT_2').lastMeasurement.value,
            item.getSensorBySensorTypeKey('VTRON_OUT_VOLT_3').lastMeasurement.value
                * item.getSensorBySensorTypeKey('VTRON_OUT_CURRENT_3').lastMeasurement.value,
          ) / 1000,
          unit: 'kW',
        },
        {
          key: 'VTRON_OUT_CURRENT_1',
          quantity: 'I',
          parse: (item) => Math.max(
            item.getSensorBySensorTypeKey('VTRON_OUT_CURRENT_1').lastMeasurement.value,
            item.getSensorBySensorTypeKey('VTRON_OUT_CURRENT_2').lastMeasurement.value,
            item.getSensorBySensorTypeKey('VTRON_OUT_CURRENT_3').lastMeasurement.value,
          ),
        },
      ];
    },
  },
  methods: {
    startInterval() {
      if (!this.interval || this.pageCount <= 1 || this.pageProgressInterval) return;

      const updateTime = 100;
      this.pageProgressInterval = setInterval(() => {
        this.pageProgress += (this.interval / updateTime / this.interval) * 100;
        if (this.pageProgress >= 100) {
          this.pageProgress = 0;
          const page = (this.page + 1) % (this.pageCount + 1);
          this.page = page === 0 ? 1 : page;
          clearInterval(this.pageProgressInterval);
          this.pageProgressInterval = null;
          setTimeout(() => {
            this.startInterval();
          }, 200);
        }
      }, updateTime);
    },
    getFuelLevel(asset) {
      return asset.getSensorBySensorTypeKey('FUEL').lastMeasurement.value;
    },
    getBatteryLevel(asset) {
      return asset.getSensorBySensorTypeKey('VTRON_SOC')?.lastMeasurement.value;
    },
    getTemp(asset) {
      return asset.getSensorBySensorTypeKey('ROOM_PB1')?.lastMeasurement.value;
    },
    getUnit(asset, key) {
      return asset.getSensorBySensorTypeKey(key).sensorType.unit;
    },
    getSensorValue(asset, sensorKey) {
      return asset.getSensorBySensorTypeKey(sensorKey)?.lastMeasurement?.value;
    },
  },
  watch: {
    pageCount() {
      this.startInterval();
    },
    sortByInner() {
      this.$emit('update:sortBy', this.sortByInner);
    },
    sortDescInner() {
      this.$emit('update:sortDesc', this.sortDescInner);
    },
    sortBy(s) {
      this.sortByInner = s;
    },
    sortDesc(s) {
      this.sortDescInner = s;
    },
    itemsPerPage(items) {
      if (!this.hideDefaultHeader) {
        localStorage.setItem(this.localStorageItemsPerPageKey, items);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.update);
  },
});
</script>
