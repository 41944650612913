<template lang="pug">
  span
    v-tooltip(top)
      template(v-slot:activator="{ on: tooltip }")
        v-avatar(
          v-on="tooltip"
          :color="organization ? organization.color : 'grey lighten-1'"
          :size="size"
          :left="left"
          :right="right"
        )
          v-img(
            v-if="organization"
            :src="organization.logoSrc"
            contain
          )
          v-icon(v-else color="white" small)
            | mdi-office-building
      span(v-if="organization") {{ organization.name }}
      span(v-else) {{ $tc('organizations.organization', 0) }}
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'OrganizationAvatar',
  props: {
    organizationId: String,
    size: {
      type: String,
      default: undefined,
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'getOrganizations',
      'getOrganization',
      'hasOrganizations',
      'isLoadingOrganizations',
    ]),
    organization() {
      if (!this.organizationId) return false;
      if (!this.getOrganization(this.organizationId)) return false;
      return this.getOrganization(this.organizationId);
    },
  },
});
</script>
