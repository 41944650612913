<template lang="pug">
  div
    v-row
      template(
        v-if="isLoadingAssetProjects"
      )
        v-col(
          v-for="proj in Array(3)"
          :key="proj"
        )
          v-card
            v-skeleton-loader(
              type="card"
            )
      v-col(col="12" v-else-if="!hasAssetProjects")
        v-card
          v-card-text
            em {{ $t('asset-projects.none-found') }}
      v-col(
        v-else
        v-for="assetProject in calculated"
        :key="assetProject.id"
      )
        v-card.fill-height(
          :loading="isLoadingAssets || isLoadingAssetProjects"
        )
          v-list-item
            v-list-item-content
              v-list-item-title.text-h6 {{ assetProject.name }}
              v-list-item-subtitle {{ $tc('asset-projects.project', 1) }}
            v-list-item-action
              v-menu(
                v-if="!viewOnly"
                bottom
                left
              )
                template(v-slot:activator="{ on, attrs }")
                  v-btn(
                    icon
                    v-bind="attrs"
                    v-on="on"
                  )
                    v-icon mdi-dots-vertical
                v-list
                  v-list-item(
                    @click="assetProjectsManager.editObject(assetProject)"
                  )
                    v-list-item-icon
                      v-icon mdi-pencil
                    v-list-item-content
                      v-list-item-title {{ $t('item.edit', { item: assetProject.name }) }}
                  v-list-item(
                    @click="assetProjectsManager.deleteObject(assetProject)"
                  )
                    v-list-item-icon
                      v-icon mdi-delete
                    v-list-item-content
                      v-list-item-title {{ $t('item.delete', { item: assetProject.name }) }}
                  v-list-item(
                    @click="copyText(assetProject.id)"
                  )
                    v-list-item-icon
                      v-icon mdi-identifier
                    v-list-item-content
                      v-list-item-title {{ $t('item.copy', { item: 'ID' }) }}
          v-card-text
            v-chip-group(column)
              v-chip(
                v-for="asset in assetProject.assets"
                :key="`${assetProject.id}_${asset.id}`"
                :to="{ name: 'asset', params: { id: asset.id } }"
                small
              )
                OrganizationAvatar(
                  :organization-id="asset.organizationId"
                  left
                )
                | {{ asset.name }}

                template(v-if="asset.malfunction || asset.numberOfAlarmErrors > 0")
                  v-icon(
                    v-if="asset.malfunction"
                    small
                    color="warning"
                    right
                  ) mdi-alert-circle-outline
                  template(v-if="asset.numberOfAlarmErrors > 0")
                    v-icon(
                      v-if="asset.malfunction"
                      small
                      right
                      color="error"
                    ) mdi-bell-alert-outline
                    v-avatar(right)
                      strong {{ asset.numberOfAlarmErrors }}
                v-icon(v-else small right color="success") mdi-check-circle
            v-chip-group(column)
              v-chip(
                v-for="agg in assetProject.aggregations"
                :key="`${assetProject \
                  .id}_${agg.calculation.sensorTypeId}_${agg.calculation.aggregation}`"
                small
                color="primary"
                label
              )
                template(v-if="agg.sensorType")
                  v-icon(left small) {{ aggregationIcon[agg.calculation.aggregation] }}
                  span {{ agg.sensorType.name }}&nbsp;
                  strong(v-if="agg.calculation.aggregation === Aggregation.No")
                    | {{ agg.calculated.map((c) => c.toFixed(0)).join(', ') }}
                  strong(v-else) {{ agg.calculated | round }}
                  span.grey--text.text--lighten-2 {{ agg.sensorType.unit }}
                template(v-else)
                  v-icon(left) mdi-spin mdi-loading
                  em {{ $t('loading') }}
    v-row(v-if="!viewOnly")
      v-col
        v-card(flat color="transparent")
          v-card-actions
            v-spacer
            ManageObject(
              ref="assetProjectsManager"
              :objectText="$tc('asset-projects.project', 1)"
              objectTitleKey="name"
              :defaultObject="defaultAssetProject"
              actionAdd="ADD_ASSET_PROJECT"
              actionUpdate="UPDATE_ASSET_PROJECT"
              actionDelete="DELETE_ASSET_PROJECT"
            )
              template(
                v-slot:form="{ edittingObject }"
              )
                v-text-field(
                  v-model="edittingObject.name"
                  :label="$t('name')"
                  :rules="[mandatoryRule]"
                  required
                )
                v-autocomplete(
                  v-model="edittingObject.assetIds"
                  :items="getAssets"
                  :label="$t('asset-projects.applicable.assets')"
                  item-text="name"
                  item-value="id"
                  small-chips
                  clearable
                  deletable-chips
                  multiple
                  :rules="[notEmptyRule]"
                )
                v-card.my-4
                  v-subheader {{ $tc('asset-projects.sensor-value-aggregation', 2) }}
                  v-card-text
                    v-alert(
                      v-if="edittingObject.assetIds.length === 0"
                      type="warning"
                      colored-border
                      border="left"
                      elevation="2"
                    )
                      | {{ $t('asset-projects.select-asset') }}
                    em(v-else-if="edittingObject.sensorTypeCalculations.length === 0")
                      | {{ $tc('asset-projects.sensor-value-aggregation', 0) }}
                    v-row(
                      v-else
                      v-for="(calculation, cindex) in edittingObject.sensorTypeCalculations"
                      :key="cindex"
                    )
                      v-col(cols="6")
                        v-autocomplete(
                          v-model="calculation.sensorTypeId"
                          :items="filteredSensorTypes(edittingObject.assetIds)"
                          :label="$t('asset-projects.applicable.sensor-types')"
                          item-text="name"
                          item-value="id"
                          clearable
                          :rules="[mandatoryRule]"
                        )
                          template(v-slot:item="data")
                            v-list-item-action
                              v-checkbox(:input-value="data.attrs.inputValue")
                            v-list-item-content
                              v-list-item-title {{ data.item.name }}
                                strong.ml-2(v-html="data.item.unit")
                              v-list-item-subtitle {{ data.item.meta.source }}
                      v-col(cols="6")
                        v-select(
                          v-model="calculation.aggregation"
                          :items="aggregations"
                          :label="$t('aggregation')"
                          append-outer-icon="mdi-delete"
                          @click:append-outer="removeAggregation(edittingObject, cindex)"
                        )
                  v-card-actions
                    v-spacer
                    v-btn(
                      :disabled="edittingObject.assetIds.length === 0"
                      small
                      color="primary"
                      text
                      @click="edittingObject.sensorTypeCalculations.push({ \
                        sensorTypeId: null, \
                        aggregation: null, \
                      })"
                    )
                      v-icon mdi-plus
                      | {{ $t('asset-projects.add-sensor-value-aggregation') }}
                GroupsAccess(
                  v-model="edittingObject.groups"
                )
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Aggregation, aggregationIcon, aggregations } from '@/models/sensor-type-calculation';
import OrganizationAvatar from '@/components/organization/OrganizationAvatar.vue';
import ManageObject from '@/components/misc/ManageObject.vue';
import GroupsAccess from '@/components/form/GroupsAccess.vue';

export default Vue.extend({
  name: 'AssetProjects',

  components: {
    OrganizationAvatar,
    ManageObject,
    GroupsAccess,
  },
  props: {
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      aggregationIcon,
      Aggregation,
      defaultAssetProject: {
        name: '',
        assetIds: [],
        sensorTypeCalculations: [],
        groups: [],
      },
    };
  },
  mounted() {
    this.$store.dispatch('FETCH_ASSET_PROJECTS');
    this.$store.dispatch('GET_SENSOR_TYPES');
  },
  computed: {
    ...mapGetters([
      'getAssetProjects',
      'hasAssetProjects',
      'isLoadingAssetProjects',
      'getAssets',
      'getAssetType',
      'isLoadingAssets',
      'getSensorTypes',
    ]),
    calculated() {
      const projects = this.getAssetProjects.map((ap) => ({
        ...ap,
        aggregations: ap.calculateAggregatedValues(this.getAssets),
        assets: this.getAssets.filter((asset) => ap.assetIds.includes(asset.id)),
      }));
      return projects;
    },
    assetProjectsManager() {
      return this.$refs.assetProjectsManager;
    },
    aggregations() {
      return aggregations.map((agg) => ({
        value: agg,
        text: this.$t(`aggregations.${agg}`),
      }));
    },
  },
  methods: {
    filteredSensorTypes(assetIds) {
      const sensorTypeIds = this.getAssets.filter((a) => assetIds.includes(a.id))
        .map((a) => a.sensors.map((s) => s.sensorType.id))
        .flat();
      return this.getSensorTypes
        .filter((st) => sensorTypeIds.includes(st.id));
    },
    removeAggregation(edittingObject, index) {
      edittingObject.sensorTypeCalculations.splice(index, 1);
    },
  },
});
</script>
